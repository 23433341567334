<template>
  <ModalOnOverlay
    class="success-modal"
    v-if="visible"
    @close="$emit('close')"
    :footerEnabled="false"
    :style="{ minHeight: '309px' }"
  >
    <template #body>
      <success-icon/>
      <div class="success-modal--title text-24-800">
        {{ title }}
      </div>
      <div class="success-modal--text">
        {{ description }}
      </div>
    </template>
  </ModalOnOverlay>
</template>

<script>
import ModalOnOverlay from '@/components/ui/modals/ModalOnOverlay'
import SuccessIcon from '../SuccessIcon'
export default {
  name: 'SuccessModal',
  components: {
    SuccessIcon,
    ModalOnOverlay,
  },
  props: {
    visible: Boolean,
    title: String,
    description: String,
  },
}
</script>

<style scoped lang="scss">
.success-modal {
  &--title {
    margin-bottom: 12px;
    text-align: center;
  }

  &--text {
    text-align: center;
  }
}
</style>
