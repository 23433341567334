<template>
  <div class="success-icon">
    <svg-icon iconName="checkmark-thin" className="success-icon--svg"/>
  </div>
</template>

<script>
import SvgIcon from 'shared/src/components/SvgIcon'

export default {
  name: 'success-icon',
  components: { SvgIcon },
}
</script>

<style scoped lang="scss">
.success-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: #D8FFEA;
  margin: 0 auto 12px;

  &--svg {
    fill: transparent;
    stroke: $color-primary;
    width: 35px !important;
    height: 20px !important;
  }
}
</style>
